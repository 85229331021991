.select-filter {
  position: relative;
  height: 38px;
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e);
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;    
  
  .select-box {
    width: 100%;
    display: inline-block;
  }
  .select-box .options-container {
    display: none;
    margin-left: -12px;
    margin-top: 10px;
    border: 1px solid #ccc;
    background-color: white;
    
    position: fixed;
    border-radius: 5px;
    z-index: 9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);    
  }

  .options {
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
  }

  .select-box.open .options-container {
    display: block;
  }

  .search-input {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
  }

  .option {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .option:hover {
    background-color: #f0f0f0;
  }
}
