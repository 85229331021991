//
// alerts.scss
//

.alert-top-border {
    background-color: var(--#{$prefix}white);
    border-color: var(--#{$prefix}light);
    border-top: 2px solid $light;
}

.alert-outline{
    background-color: var(--#{$prefix}white);
}

.alert-light{
    color: $text-muted;
}

@each $color,
$value in $theme-colors {
    .alert-top-border{
        &.alert-#{$color} {
            border-top-color: $value;
            color: $value;
        }
    }

    .alert-outline{
        &.alert-#{$color} {
            border: 1px solid rgba($value, .8);
            color: $value;
        }
    }
}

.alert-top-border, .alert-outline{
    &.alert-light{
        color: $text-muted;
    }
    &.alert-dark{
        color:  var(--#{$prefix}dark);
        border-top-color: var(--#{$prefix}border-color);
    }
    &.alert-outline{
        border-color: var(--#{$prefix}border-color);
    }
}

.alert-purple {
    --bs-alert-color: #342c6a;
    --bs-alert-bg: #dddbef;
    --bs-alert-border-color: #ccc9e8;
    --bs-alert-link-color: #342c6a;
}

[data-bs-theme="dark"] {
    .alert-purple {
        --bs-alert-color: #9f92fd;
        --bs-alert-bg: #413a78;
        --bs-alert-border-color: #564ab1;
        --bs-alert-link-color: #342c6a;
    }
}