//
// choices
//

.choices__inner {
  padding: 0.25rem ($form-select-padding-x + $form-select-indicator-padding) 0.25rem 0.5rem;
  background-color: $form-select-bg;
  vertical-align: middle;
  border-radius: $form-select-border-radius;
  border: $form-select-border-width solid var(--#{$prefix}border-color);
  min-height: 38px;
}


.choices[data-type*=select-one] {
  .choices__inner {
    padding-bottom: 0.25rem;
  }

  &:after {
    border: 0;
    border-color: $dark transparent transparent;
    right: 11.5px;
    left: auto;
    content: "\F0140";
    font-family: 'Material Design Icons';
    width: auto;
    height: auto;
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-open:after {
    margin-top: 0;
    transform: translateY(-50%) rotate(-180deg);
  }

  .choices__button {
    right: 0;
    left: auto;
    margin-right: 25px;
    margin-left: 0;
  }

  .choices__input {
    background-color: transparent;
    border-color: var(--#{$prefix}border-color);
    color: var(--#{$prefix}secondary-color);
  }
}

.choices__input {
  background-color: $input-bg;
  margin-bottom: 0;
  color: var(--#{$prefix}secondary-color);
}

.choices__list--multiple {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 6px;

  .choices__item {
    background-color: $primary;
    border-color: $primary;
    margin-bottom: 0;
    margin-right: 0;
    font-weight: $font-weight-normal;

    &.is-highlighted {
      background-color: $primary;
      border: 1px solid $primary;
    }
  }
}

.choices.is-disabled {

  .choices__inner,
  .choices__input {
    background-color: var(--#{$prefix}light);
  }
}

.is-open,
.is-focused {
  .choices__inner {
    border-color: var(--#{$prefix}border-color);
  }
}

.is-disabled .choices__list--multiple {
  .choices__item {
    background-color: $secondary;
    border-color: $secondary;
  }
}

.choices[data-type*=select-multiple],
.choices[data-type*=text] {
  .choices__button {
    border-left: 1px solid rgba($white, 0.5);
    margin: 0 -4px 0 8px;
    border-right: 0;
  }
}

.choices__list--dropdown {
  border-color: var(--#{$prefix}border-color) !important;
  box-shadow: var(--#{$prefix}box-shadow);
  background-color: var(--#{$prefix}white);

  .choices__item--selectable.is-highlighted {
    background-color: var(--#{$prefix}light);
  }
}

.is-open {
  .choices__list--dropdown {
    border-color:var(--#{$prefix}border-color);
  }
}

.choices__heading{
  border-color: var(--#{$prefix}border-color) !important;
}


// selact 2

.select__control,
.select2-selection,
.select2-selection__control{
    background-color: $input-bg !important;
    color: $text-muted;
    border-color: var(--#{$prefix}gray-300) !important;
}

.select__multi-value,
.select2-selection__multi-value__label,
.form-control__multi-value__label,
.select__multi-value__label{
  background-color: $primary !important;
  color: $white !important;
  border-radius: 2px 0px 0px 2px !important;
}

.select2-selection__multi-value,
.form-control__multi-value__remove,
.select__multi-value__remove{
  background-color: $primary !important;
  color: $white !important;
  border-radius: 0px 2px 2px 0px !important;
  &:hover{
    background-color: $primary !important;
  }
}

.select2-selection__placeholder{
  color: $text-muted !important;
}

.css-12a83d4-MultiValueRemove:hover{
  background-color: $primary !important;
  color: $white !important;
}

.select__indicator,
.form-control__indicator{
  color: $text-muted !important;
}



.select__menu,
.form-control__menu,
.select2-selection__menu{
  background-color: $input-bg !important;
}