.transfer-list-container {
  .list-group {
    height: 300px;
    min-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 0;
    border-radius: 4px;
  }

  .list-group-item {
    cursor: pointer;
  }

  .transfer-controls {
    button {
      width: 60px;
      height: 40px;
    }
  }

  .list-group-item.active {
    background-color: #007bff;
    color: white;
  }
}
