@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.loading-base {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/auth-bg.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  z-index: 9999; // Alta prioridade para estar sobre outros elementos

  // Posicionamento no topo
  &.loading-position-top {
    top: 0;
    position: fixed; // Fixo na tela
    left: 0;
    right: 0;
  }

  // Posicionamento na base
  &.loading-position-bottom {
    bottom: 0;
    position: fixed; // Fixo na tela
    left: 0;
    right: 0;
  }

  // Tela cheia (fullscreen)
  &.loading-position-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh !important;
  }
}

.loading-container {
  width: 100%;
  max-width: 520px;
  text-align: center;
  color: #74788d;
  position: relative;
  margin: 0 32px;
  display: flex; // Ativar flexbox para centralização
  align-items: center; // Centralizar verticalmente
  justify-content: center; // Centralizar horizontalmente

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    animation: movingLine 2.4s infinite ease-in-out;
  }
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }
  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }
  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}

.loading-text {
  font-size: 5vw;
  line-height: 64px;
  letter-spacing: 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-evenly;

  span {
    animation: moveLetters 2.4s infinite ease-in-out;
    transform: translatex(0);
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
  }
}

// Define animações para cada letra individualmente com um delay crescente
@for $i from 1 through 7 {
  .loading-text span:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }
  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}
