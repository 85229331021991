.tree-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tree-checkbox {
  margin-right: 8px;
}

.tree-label {
  cursor: pointer;
  margin-right: 8px;
}

.tree-toggle-btn {
  margin-right: 8px; /* Coloca o botão na frente do checkbox */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #007bff;
}

.tree-toggle-btn:focus {
  outline: none;
}

.tree-subitems {
  margin-left: 40px; /* Aumenta a margem para funções para dar clareza na hierarquia */
}

.tree-item-level {
  display: flex;
  align-items: center;
  margin-left: 0px; /* Alinhamento dos módulos no nível raiz */
}

.tree-icon {
  margin-right: 8px; /* Adiciona espaço à direita do ícone */
}

.tree-label {
  cursor: pointer;
}
