// SCSS para o uploader de imagens
.image-uploader {
  border: 2px dashed rgb(151, 151, 151);
  border-radius: 8px;
  text-align: center;
  width: 468px;
  height: 295px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  label {
    font-size: 1.25em;
    font-weight: 700;
    color: black;
    font-family: sans-serif;
    cursor: pointer;
    display: inline-block;
    line-height: 295px; // Altere isso se mudar a altura da div
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  // Estilos para o botão de fechar
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
