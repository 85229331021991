.tooltip {
    visibility: hidden;
    position: fixed;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: pre-wrap;
    z-index: 999999;
    max-width: 250px;
  }
  .tooltip.top::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.7);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .tooltip.right::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid rgba(0, 0, 0, 0.7);
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .tooltip.left::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgba(0, 0, 0, 0.7);
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .tooltip.bottom::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 0.7);
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tooltip-icon {
    cursor: pointer;
  }
  .tooltip-overlay {
    height: 100%;
  }