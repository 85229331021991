.button-actions {
  display: flex;
  justify-content: center;
  gap: 5px; /* Espaço entre os botões */
}

.button-actions span {
  display: inline-block;
  border-radius: var(
    --bs-border-radius-pill
  ); /* Borda arredondada padrão do Bootstrap */
  border: none;
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  line-height: 32px; /* Alinha o ícone verticalmente */
  background-color: var(
    --bs-gray-300
  ); /* Cor de fundo que se ajusta ao modo claro/escuro */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-actions span:hover {
  background-color: var(
    --bs-gray-500
  ); /* Cor ao passar o mouse que se adapta ao tema */
  color: var(--bs-white);
}

.button-actions span i {
  font-size: 16px; /* Tamanho do ícone */
  color: var(
    --bs-body-color
  ); /* Cor do ícone que se adapta ao modo escuro/claro */
}

.button-actions span:hover i {
  color: var(
    --bs-white
  ) !important; /* Cor do ícone ao passar o mouse, se adapta ao tema */
}
