@import "/src/assets/scss/app.scss";

.timeline-item-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem 0 1.25rem;
  color: var(--bs-white);
}

// vertical center timeline

.verti-timeline {
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    top: 16px;
    left: 0;
    bottom: 0;
    background-color: rgba($blue, 0.07);
  }

  .timeline-item {
    display: flex;
  }

  .timeline-block {
    width: 100%;
  }

  .time-show-btn {
    margin-bottom: 30px;
  }

  .timeline-box {
    margin: 20px 0;
    position: relative;
    margin-left: 45px;
    box-shadow: none;
    border: 1px solid var(--#{$prefix}border-color);
    border-radius: 6px;
    border-left: 2px solid $primary;
    word-wrap: break-word;
    background-color: var(--bs-white);
    background-clip: border-box;

    /* Exibe a bolinha por padrão */
    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background: $primary;
      border-radius: 50%;
      display: block;
      border: 4px solid rgba(var(--#{$prefix}light-rgb), 0.75);
      left: -53px;
      top: 32px;
      z-index: 9;
    }

    &:after {
      content: "";
      position: absolute;
      border: 10px solid transparent;
      border-right-color: $primary;
      top: 30px;
      left: -20px;
    }
  }

  /* Álbum de fotos na timeline */
  .timeline-album {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    a {
      display: block;

      img {
        height: 40px;
        width: auto;
        border-radius: 4px;
      }
    }
  }
}

@media (min-width: 768px) {
  .verti-timeline {
    .timeline-block {
      width: 50%;
    }

    &:before {
      left: 50%;
    }

    .time-show-btn {
      position: relative;
      left: 67px;
      text-align: right;

      &.badge {
        height: 30px;
        border-radius: 6px;
      }
    }
  }

  .timeline-item {
    &::before {
      content: "";
      display: block;
      width: 50%;
    }

    &.left {
      text-align: right;

      &::after {
        content: "";
        display: block;
        width: 50%;
      }

      &::before {
        display: none;
      }
    }

    .timeline-box {
      margin-left: 45px;
    }

    &.left .timeline-box {
      margin-left: 0;
      margin-right: 45px;
      border-left: 1px solid var(--#{$prefix}border-color);
      border-right: 2px solid $primary;

      &:before {
        left: auto;
        right: -55px;
      }

      &:after {
        left: auto;
        right: -20px;
        border-right-color: transparent;
        border-left-color: $primary;
      }
    }
  }
}

// center timeline

.center-timeline .timeline-item.separator {
  text-align: right;

  &::after {
    content: "";
    display: block;
    width: 50%;
  }

  &::before {
    display: none;
  }
}

.swiper-slide-arrow {
  .swiper-button-prev {
    left: -9px !important;
  }

  .swiper-button-next {
    right: -9px !important;
  }
}

// Left Timeline

.verti-timeline {
  &.left-timeline {
    .timeline-block {
      width: 100%;
    }

    &:before {
      left: 0;
    }

    .time-show-btn {
      left: 0;
      text-align: left;
    }

    .timeline-item {
      &::before,
      &.left::after {
        display: none;
      }

      &.left {
        text-align: left;

        .timeline-box {
          margin-right: 0;
          margin-left: 45px;
          border-right: 1px solid var(--#{$prefix}border-color);
          border-left: 2px solid $primary;

          &:after {
            right: auto;
            left: -20px;
            border-left-color: transparent;
            border-right-color: $primary;
          }

          &::before {
            right: auto;
            left: -55px;
          }
        }
      }
    }
  }
}

// Bolinha padrão (sem ícone)

.timeline-box::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: $primary;
  border-radius: 50%;
  display: block;
  border: 4px solid rgba(var(--#{$prefix}light-rgb), 0.75);
  left: -53px;
  top: 32px;
  z-index: 9;
}

// Estilo do losango (quando o ícone estiver presente)

.timeline-icon {
  position: relative;
  top: 72px;
  left: -11px; /* Posição padrão para a direita */
  width: 24px;
  height: 24px;
  z-index: 10;

  .diamond {
    width: 100%;
    height: 100%;
    background-color: $primary;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    transform: rotate(-45deg); /* Desfaz a rotação do ícone */
    color: var(--bs-white);
    font-size: 12px !important;
  }
}

/* Se o item da timeline estiver à esquerda, mova o ícone para a direita dentro do bloco de 50% */
.center-timeline .timeline-item.left .timeline-icon {
  left: 98%; /* Move o ícone para o final do bloco de 50% */
  right: auto; /* Remove qualquer valor de 'right' */
}

// Oculta a bolinha se o ícone estiver presente

.timeline-icon + .timeline-box::before {
  display: none;
}

.timeline-item-footer {
  font-size: 0.8rem; /* Tamanho de fonte menor */
  padding: 5px 10px 5px;
  border-top: 1px solid var(--bs-border-color);
  margin-left: 10px;
  margin-right: 10px;
}

.timeline-item-footer a {
  color: inherit; /* Mantém a cor original se for um link */
  text-decoration: none; /* Remove o sublinhado dos links */
}

.timeline-item-footer a:hover {
  text-decoration: underline; /* Adiciona sublinhado apenas no hover */
}
